import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {StatisticsComponent} from './statistics.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'subadmin/users',
    pathMatch: 'full'
  },
  {
    path: '',
    component: StatisticsComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./pages/admin-statistics/admin-statistics.module').then(m => m.AdminStatisticsModule),
      },
      {
        path: 'subadmin/users',
        loadChildren: () => import('./pages/subadmin-statistics/subadmin-statistics.module').then(m => m.SubadminStatisticsModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'subadmin/users',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatisticsRoutingModule {
}
